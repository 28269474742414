import { AUTH_API_INJECTION_TOKEN, ACCOUNTS_API_INJECTION_TOKEN, USERS_API_INJECTION_TOKEN, APP_CONFIG, ExpirationOverlay, UserRoles, } from '@onereach/auth-ui-module';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, watch, provide, ref, onErrorCaptured } from 'vue';
import AccountsApi from '@/api/AccountsApi';
import AuthApi from '@/api/Auth';
import UsersApi from '@/api/UsersApi';
import { AppPage } from '@/components';
import { useContactsInitialData, useContactsInitialDataLegacy, useI18n, useRouteMeta } from '@/composables';
import config from '@/config';
import { MigrationsStatus } from '@/constants';
import { TranslatableError } from '@/errors';
import SentryApi from '@/sentry';
import { useAuth, useMigrations } from '@/stores';
import * as stores from '@/stores';
import pkg from '../package.json';
export default defineComponent({
    components: {
        AppPage,
        ExpirationOverlay,
    },
    setup() {
        // Fix of Pinia plugin disabling
        _.entries(stores)
            .filter(([key, value]) => key.startsWith('use') && _.isFunction(value))
            .forEach(([, useCurrentStore]) => useCurrentStore());
        //provide api services to AuthPopover
        provide(AUTH_API_INJECTION_TOKEN, AuthApi);
        provide(ACCOUNTS_API_INJECTION_TOKEN, AccountsApi);
        provide(USERS_API_INJECTION_TOKEN, UsersApi);
        provide(APP_CONFIG, config);
        const lockScreenUsername = ref('');
        const errorText = ref('');
        const { t } = useI18n();
        const userHasPermission = computed(() => role.value && role.value !== UserRoles.guest);
        const showError = computed(() => {
            return !!errorText.value && !isMigrationsRequired.value && migrationsStatus.value != MigrationsStatus.UNDEFINED;
        });
        // migrations lock
        const migrationsStore = useMigrations();
        const { migrationsStatus } = storeToRefs(migrationsStore);
        const { isV2, isSettings } = useRouteMeta();
        const isMigrationsRequired = computed(() => {
            return isV2.value && migrationsStatus.value === MigrationsStatus.NOT_STARTED && !isSettings.value;
        });
        const authStore = useAuth();
        const { authorized, username, identityProvider, reactiveToken: token, cookiesAnotherUser, cookiesNoRights, cookiesExpired, userId, accountId, role, } = storeToRefs(authStore);
        useContactsInitialDataLegacy();
        useContactsInitialData();
        watch(authorized, async (newIsAuthorized) => {
            if (newIsAuthorized) {
                lockScreenUsername.value = await authStore.getUserNameForLockScreen();
            }
        });
        function cookiesRenewHandler() {
            authStore.updateCookieExpiration();
        }
        function handleCloseError() {
            errorText.value = '';
        }
        onErrorCaptured((e) => {
            if (e instanceof TranslatableError) {
                // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
                errorText.value = t(e.translationKey);
                SentryApi.captureException(e.innerError);
            }
            else {
                if (![MigrationsStatus.UNDEFINED, MigrationsStatus.NOT_STARTED].includes(migrationsStatus.value)) {
                    errorText.value = t('errors.commonText');
                }
                SentryApi.captureException(e);
            }
            return false;
        });
        return {
            cookiesAnotherUser,
            cookiesNoRights,
            username,
            accountId,
            authUrl: config.AUTH_UI_URL,
            identityProvider,
            token,
            userId,
            cookiesExpired,
            cookiesRenewHandler,
            handleCloseError,
            role,
            config,
            userHasPermission,
            serviceTranslations: pkg.projectConfig.translations,
            lockScreenUsername,
            showError,
            errorText,
            isMigrationsRequired,
        };
    },
});
