import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "app" };
const _hoisted_2 = { class: "w-full typography-body-1-regular" };
const _hoisted_3 = { class: "w-full typography-body-2-regular" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ExpirationOverlay = _resolveComponent("ExpirationOverlay");
    const _component_router_view = _resolveComponent("router-view");
    const _component_AppPage = _resolveComponent("AppPage");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ExpirationOverlay, {
            "lock-username": _ctx.lockScreenUsername,
            username: _ctx.username,
            "auth-url": _ctx.authUrl,
            "identity-provider": _ctx.identityProvider,
            token: _ctx.token,
            "user-id": _ctx.userId,
            "account-id": _ctx.accountId,
            "cookies-expired": _ctx.cookiesExpired,
            "cookies-another-user": _ctx.cookiesAnotherUser,
            "cookies-no-rights": _ctx.cookiesNoRights,
            "login-overlay-disabled": false,
            onOnUpdateCookieExpiration: _ctx.cookiesRenewHandler
        }, null, 8, ["lock-username", "username", "auth-url", "identity-provider", "token", "user-id", "account-id", "cookies-expired", "cookies-another-user", "cookies-no-rights", "onOnUpdateCookieExpiration"]),
        (_ctx.userHasPermission)
            ? (_openBlock(), _createBlock(_component_AppPage, {
                key: 0,
                "account-id": _ctx.accountId,
                "user-role": _ctx.role,
                token: _ctx.token,
                config: _ctx.config,
                "service-translations": _ctx.serviceTranslations,
                "error-text": _ctx.errorText,
                "show-error": _ctx.showError,
                "onUpdate:showError": _ctx.handleCloseError
            }, {
                default: _withCtx(() => [
                    (_ctx.isMigrationsRequired)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('errors.migrations.setup1')), 1),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('errors.migrations.setup2')), 1)
                        ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (_ctx.userHasPermission)
                                ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                                : _createCommentVNode("", true)
                        ], 64))
                ]),
                _: 1
            }, 8, ["account-id", "user-role", "token", "config", "service-translations", "error-text", "show-error", "onUpdate:showError"]))
            : _createCommentVNode("", true)
    ]));
}
